import React from "react";

import { Container } from "../../../";
import { v4 as uuidv4 } from "uuid";
import * as css from "./style.module.scss";
import { Button, Radio, Input, Space } from "antd";
import { connect } from "react-redux";

import Toastify from "toastify-js";

const EligibiltyQuestionsRaw = (props) => {
  const [isEligibilityToastOpen, setIsEligibilityToastOpen] =
    React.useState(false);

  const answerQuesion = (v, q, index) => {
    props.answerEligibilibityQuestion({
      ...q,
      index,
      answer: q.possibleAnswers[Number(!v.target.value)],
    });

    if (
      v.target.checked &&
      q.possibleAnswers[Number(!v.target.value)].isKnockOut
    ) {
      if (isEligibilityToastOpen) return;
      Toastify({
        text: q.possibleAnswers[Number(!v.target.checked)].errorMessage,
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "bottom",
        position: "center",
        backgroundColor: "#D33F49",
        stopOnFocus: true,
        className: "notification",
        callback: () => setIsEligibilityToastOpen(false),
      }).showToast();
      setIsEligibilityToastOpen(true);
    }
  };

  return (
    <Container>
      {props.applications.map((application, i) => {
        if (application.eligibilityQuestions.length == 0) return null;

        const questions = application.eligibilityQuestions.map((question) => {
          question.possibleAnswers.forEach((q) => {
            if (!["Yes", "No"].includes(q.answerText)) {
              throw new Error("UNEXPECTED QUESTION FORMAT");
            }
          });

          return {
            id: uuidv4(),
            application_index: i,
            question: question.questionText,
            possibleAnswers: question.possibleAnswers,
            answer: question.answer,
            errorMessage: question.errorMessage,
          };
        });

        return (
          <Container
            key={i + "elibibility-key"}
            className={css.eligibilityQuestionsContainer}
          >
            <Container className={css.eligibilityQuestionsInnerContainer}>
              <Container
                className={`${css.eligibilityQuestionsTitle} ${
                  questions.some((q) => q.answer && q.answer.isKnockOut)
                    ? css.blocked
                    : questions.every((q) => q.answer)
                    ? css.allowed
                    : ""
                }`}
              >
                {application.eligibilityTitle}
              </Container>
              <Container className={css.eligibilityQuestions}>
                {questions.map((q, index) => {
                  return (
                    <Container className={css.eligibilityQuestion} key={q.id}>
                      <Container style={{ display: "flex" }}>
                        <Container className={css.eligibilityQuestionIndex}>
                          {index + 1}.
                        </Container>
                        <Container
                          className={`${css.eligibilityQuestionText} ${
                            q.answer
                              ? q.answer.isKnockOut
                                ? css.eligibilityQuestionError
                                : css.eligibilityQuestionOk
                              : ""
                          } `}
                        >
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{ __html: q.question }}
                          />{" "}
                        </Container>
                      </Container>
                      <Container
                        className={`${css.eligibilityQuestionRadio} hidden-on-mobile`}
                      >
                        <Radio.Group
                          value={
                            q.answer
                              ? q.answer.answerText == "Yes"
                                ? 1
                                : 0
                              : undefined
                          }
                          onChange={(v) => {
                            answerQuesion(v, q, index);
                          }}
                          buttonStyle="solid"
                          size="large"
                        >
                          <Space direction="vertical">
                            <Radio value={1}>Yes</Radio>
                            <Radio value={0}>No</Radio>
                          </Space>
                        </Radio.Group>
                      </Container>
                      <Container
                        className={`${css.eligibilityQuestionRadio} hidden-on-web`}
                      >
                        <Radio.Group
                          value={
                            q.answer
                              ? q.answer.answerText == "Yes"
                                ? 1
                                : 0
                              : undefined
                          }
                          onChange={(v) => {
                            answerQuesion(v, q, index);
                          }}
                          buttonStyle="solid"
                          size="large"
                        >
                          <Radio value={1}>Yes</Radio>
                          <Radio value={0}>No</Radio>
                        </Radio.Group>
                      </Container>
                    </Container>
                  );
                })}
              </Container>
            </Container>
          </Container>
        );
      })}

      <Container className={css.nextButton}>
        <Button
          onClick={() => {
            props.next && props.next();
          }}
          disabled={props.applications.some((a) =>
            a.eligibilityQuestions.some(
              (q) => !q.answer || (q.answer && q.answer.isKnockOut)
            )
          )}
          type="primary"
          shape="round"
          name="test-enrollment-enrollment-tools-eligibility-button-next"
        >
          Next
        </Button>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({ enrollment }) => {
  return {
    applications: [...enrollment.nationalGeneral.applications],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    answerEligibilibityQuestion: (payload) =>
      dispatch({ type: `ANSWER_ELIGIBILITY_QUESTION`, payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EligibiltyQuestionsRaw);
