// extracted by mini-css-extract-plugin
export var allowed = "style-module--allowed--PnasY";
export var blocked = "style-module--blocked--hMZ9R";
export var eligibilityQuestion = "style-module--eligibility-question--KShMc";
export var eligibilityQuestionError = "style-module--eligibility-question-error--3OM+y";
export var eligibilityQuestionIndex = "style-module--eligibility-question-index--2Ai06";
export var eligibilityQuestionOk = "style-module--eligibility-question-ok--I7s1w";
export var eligibilityQuestionRadio = "style-module--eligibility-question-radio--CnFRM";
export var eligibilityQuestionText = "style-module--eligibility-question-text--g-JVz";
export var eligibilityQuestions = "style-module--eligibility-questions--HVBd2";
export var eligibilityQuestionsContainer = "style-module--eligibility-questions-container--2jXqn";
export var eligibilityQuestionsInnerContainer = "style-module--eligibility-questions-inner-container--pZAdl";
export var eligibilityQuestionsTitle = "style-module--eligibility-questions-title--cVUMm";
export var nextButton = "style-module--next-button--wFx2F";